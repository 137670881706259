
import {defineComponent, ref} from 'vue'
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import BaseCustomForm from "@/views/custome-form/BaseCustomForm.vue";
import FormSchemaService from "@/core/services/FormSchemaService";
import FormService from "@/core/services/FormService";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "CustomForms",
  components: {BaseCustomForm, EntityLoading, BaseModal, QuickAction},
  props: {
    object: {type: Object, required: true},
  },
  setup(props) {
    const forms = ref<any>([])
    const loading = ref(true);
    const state = ref({
      modelName: 'NEW COC',
      id: '',
      model: {schema: {}, data: {}, name: '', objectType: '', objId: ''},
      loading: true
    })
    const filter = ref({
      paginationPage: 1,
      paginationSize: 100,
      key: {objectId: props.object.objectId, objectType: props.object.objectType}
    })
    const load = () => {
      loading.value = true;
      FormService.findAll<any>(filter.value).then(res => {
        forms.value = res.data;
      }).finally(() => {
        loading.value = false;
      })
    }
    load();
    return {
      load,
      forms,
      state,
      ...LoadPanel(),
    }
  },
  methods: {
    onSaved() {
      const modal = this.$refs.modalCreateCustomFormRef as typeof BaseModal;
      modal.hideBaseModal();
      this.load();
    },
    onAdd(code) {
      this.state.loading = true;
      const data = {}
      FormSchemaService.get<any>(code).then(res => {
        res.fields.forEach(item => {
          data[item.name] = ''
        })
        this.state.id = ''
        this.state.model.schema = res;
        this.state.model.data = data;
        this.state.model.name = ''
        this.state.model.objectType = this.object.objectType
        this.state.model.objId = this.object.objectId
      }).finally(() => {
        this.state.loading = false;
      })
      const modal = this.$refs.modalCreateCustomFormRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onEdit(id) {
      this.state.loading = true;
      this.state.modelName = 'Edit COC'
      FormService.get<any>(id).then(res => {
        this.state.model.schema = res.schema;
        this.state.model.data = res.data;
        this.state.model.name = res.name
        this.state.id = res.id;
        this.state.model.objectType = this.object.objectType
        this.state.model.objId = this.object.objectId
      }).finally(() => {
        this.state.loading = false;
      })
      const modal = this.$refs.modalCreateCustomFormRef as typeof BaseModal;
      modal.showBaseModal();
    }
  }
})
