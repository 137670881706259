
import {defineComponent, PropType, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import {Field} from "vee-validate";
import CustomFormField from "@/components/base/custom-form/CustomFormField.vue";
import FormService from "@/core/services/FormService";

export default defineComponent({
  name: "BaseCustomForm",
  components: {CustomFormField, BaseForm, Field},
  props: {
    fields: {type: Object as PropType<any[]>, required: true},
    modelData: {type: Object},
    id: {type: String}
  },
  emits: ['formSaved'],
  setup(props) {
    const submitting = ref(false);
    const model = ref<any>(props.modelData);
    watch(() => props.modelData, cb => {
      model.value = cb;
    })

    return {
      submitting,
      model,
    }
  },
  methods: {
    submit() {
      this.submitting = true;
      if (this.id) {
        FormService.update(this.id, this.model).then(res => {
          this.$emit('formSaved', res);
        }).finally(() => {
          this.submitting = false;
        })
      } else {
        FormService.create(this.model).then(res => {
          this.$emit('formSaved', res);
        }).finally(() => {
          this.submitting = false;
        })
      }
    }
  }
})
